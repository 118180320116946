<template>
  <div style="margin-top: -18px">
    <div class="tools">
      <div class="item stick" @click="showQuestionnaire">
        <span class="el-icon-edit-outline f20"></span>
        <span class="titletip">问卷</span>
      </div>

      <div class="item feedback" @click="showFeedback">
        <span class="el-icon-chat-dot-square f20"></span>
        <span class="titletip">反馈</span>
      </div>

      <div class="item stick" @click="top">
        <span class="el-icon-top f20"></span>
        <span class="titletip">置顶</span>
      </div>
      <div class="item" v-popover:popover>
        <span class="el-icon-phone-outline f20"></span>
        <span class="titletip">电话</span>
      </div>
    </div>
    <el-popover
        ref="popover"
        placement="left"
        trigger="hover"
        content="0531-8260-5231">
    </el-popover>
    <el-dialog
        :visible.sync="feedBackVisible"
        class="feedback-dialog"
        :before-close="handleClose"
        width="30%">
      <template slot="title">
        <div class="i-title">我要反馈</div>
      </template>
      <el-form :model="dataForm" :rules="dataRule" ref="dataForm">
        <el-form-item class="rate" label="评分" prop="score" style="font-weight: bold;margin-top: -40px"
                      label-width="60px">
          <el-rate
              v-model="dataForm.score"
              :colors="colors"
              :texts="['极差', '差', '一般', '满意', '非常满意']"
              show-text>
          </el-rate>
        </el-form-item>
        <el-form-item prop="feedback">
          <el-input
              type="textarea"
              placeholder="请输入反馈内容..."
              v-model="dataForm.feedback"
              :rows="12"
              maxlength="500"
              show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="mt-3 uf uf-ac uf-pc">
        <el-button style="margin-right: 15px;" @click="clear">清空</el-button>
        <el-button type="primary" @click="dataFormSubmit">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="consultingVisible"
        class="feedback-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        width="30%"
        align="center"
        @close="consultCancel"
    >
      <div>
        <!--        title="预约专家，解答问题"-->
        <div class="title fwb">
          <span>预约专家，解答问题</span>
        </div>
        <div>
          <el-divider content-position="center">
            <span style="color: #9B9DA0">提交后会有专家自动联系您</span>
          </el-divider>
        </div>
        <div>
          <el-form :model="consultDataForm" ref="consult" :rules="consultRules" label-position="left"
                   label-width="auto">
            <el-form-item label="手机号码:" required prop="mobile">
              <el-input v-model="consultDataForm.mobile" placeholder="请输入手机号码（必填）"></el-input>
            </el-form-item>
            <el-form-item label="需求描述:" prop="consultContent">
              <el-input type="textarea" placeholder="请输入需求描述" v-model="consultDataForm.consultContent"
                        :autosize="{ minRows: 4, maxRows: 4}"></el-input>
            </el-form-item>
          </el-form>
          <div class="mt-3 uf uf-ac uf-pc">
            <el-button style="margin-right: 15px;" @click="consultCancel">取消</el-button>
            <el-button type="primary" @click="consultSubmit">提交</el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog
            :visible.sync="questionnaireVisible"
            class="feedback-dialog"
            :before-close="handleClose"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="50%">
      <template slot="title">
        <div class="question-title">开放式产品服务化支撑平台评价问卷调查</div>
      </template>
      <el-card shadow="never">
        <div>
          尊敬的用户：
        </div>
        <div style="text-indent: 2em;margin-bottom: 20px">您好！为了及时了解用户对平台使用的满意情况，及时收集反馈平台的不足之处，改进优化平台使用，
          邀请您参与对本平台的评价问卷调查。请您对本平台给予客观的评价，您的评价是平台不断发展进步的永恒动力。</div>
        <el-form :model="questionForm" :rules="questionRule" ref="questionForm">
        <div class="question">
          一、请对开放式产品服务化支撑平台提供服务的整体满意度进行评价
        </div>
          <el-form-item prop="question1">
            <div class="radio">
              <el-radio-group v-model="questionForm.question1">
                <el-radio label="4" name="很满意">很满意</el-radio>
                <el-radio label="3" name="满意">满意</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="不满意">不满意</el-radio>
                <el-radio label="0" name="很不满意">很不满意</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          二、您觉得开放式产品服务化支撑平台提供服务专业性怎么样
        </div>
          <el-form-item prop="question2">
            <div class="radio">
              <el-radio-group v-model="questionForm.question2">
                <el-radio label="4" name="很专业">很专业</el-radio>
                <el-radio label="3" name="专业">专业</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="不专业">不专业</el-radio>
                <el-radio label="0" name="很不专业">很不专业</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          三、您觉得开放式产品服务化支撑平台服务方为顾客提供服务的反馈效率（响应效率）怎么样
        </div>
          <el-form-item prop="question3">
            <div class="radio">
              <el-radio-group v-model="questionForm.question3">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          四、您觉得开放式产品服务化支撑平台推荐的服务多样性怎么样
        </div>
          <el-form-item prop="question4">
            <div class="radio">
              <el-radio-group v-model="questionForm.question4">
                <el-radio label="4" name="很好">很好</el-radio>
                <el-radio label="3" name="好">好</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="不好">不好</el-radio>
                <el-radio label="0" name="很不好">很不好</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          五、您觉得开放式产品服务化支撑平台吸引用户率高吗
        </div>
          <el-form-item prop="question5">
            <div class="radio">
              <el-radio-group v-model="questionForm.question5">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          六、您觉得平台提供服务的覆盖率高吗
        </div>
          <el-form-item prop="question6">
            <div class="radio">
              <el-radio-group v-model="questionForm.question6">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          七、您是否愿意“听取”系统的“建议”，对系统推荐的服务信任度怎么样
        </div>
          <el-form-item prop="question7">
            <div class="radio">
              <el-radio-group v-model="questionForm.question7">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          八、您是否满意当前专业领域的施行标准，对现行服务标准的适应度高吗
        </div>
          <el-form-item prop="question8">
            <div class="radio">
              <el-radio-group v-model="questionForm.question8">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          九、您觉得平台提供的综合服务质量高吗
        </div>
          <el-form-item prop="question9">
            <div class="radio">
              <el-radio-group v-model="questionForm.question9">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          十、您觉得使用平台服务过程中，解决问题的困难程度怎么样
        </div>
          <el-form-item prop="question10">
            <div class="radio">
              <el-radio-group v-model="questionForm.question10">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          十一、您将系统平台推荐给其他用户的可能性是多少
        </div>
          <el-form-item prop="question11">
            <div class="radio">
              <el-radio-group v-model="questionForm.question11">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          十二、您是否会愿意再次使用系统平台服务
        </div>
          <el-form-item prop="question12">
            <div class="radio">
              <el-radio-group v-model="questionForm.question12">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          十三、在平台产品或服务之后您有产生过抱怨吗
        </div>
          <el-form-item prop="question13">
            <div class="radio">
              <el-radio-group v-model="questionForm.question13">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          十四、您觉得平台综合服务的性价比怎么样
        </div>
          <el-form-item prop="question14">
            <div class="radio">
              <el-radio-group v-model="questionForm.question14">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>

        <div class="question">
          十五、您指名消费系统平台产品或服务的程度是多少
        </div>
          <el-form-item prop="question15">
            <div class="radio">
              <el-radio-group v-model="questionForm.question15">
                <el-radio label="4" name="很高">很高</el-radio>
                <el-radio label="3" name="高">高</el-radio>
                <el-radio label="2" name="一般">一般</el-radio>
                <el-radio label="1" name="低">低</el-radio>
                <el-radio label="0" name="很低">很低</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
        <div class="mt-3 uf uf-ac uf-pc">
          <el-button style="margin-right: 15px;" @click="questionnaireVisible = false">取消</el-button>
          <el-button type="primary" @click="questionnaireSubmit">提交</el-button>
        </div>
        </el-form>
      </el-card>

    </el-dialog>
  </div>
</template>
<script>
  import {checkIsNull} from "../../utils"

  export default {
    name: 'main-tools',
    data() {
      const checkQuestion = (rule, value, callback) => {
        if (checkIsNull(value)) {
          return callback(new Error('请选择'))
        }  else {
          callback()
        }
      }
      return {
        dataForm: {
          score: null,
          feedback: ''
        },
        dataRule: {
          score: [
            {required: true, message: '请打分', trigger: 'blur'}
          ],
          name: [
            {required: true, message: '反馈内容不能为空', trigger: 'blur'}
          ]
        },
        questionForm: {
          question1: '',
          question2: '',
          question3: '',
          question4: '',
          question5: '',
          question6: '',
          question7: '',
          question8: '',
          question9: '',
          question10: '',
          question11: '',
          question12: '',
          question13: '',
          question14: '',
          question15: ''
        },
        questionRule: {
          question1: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question2: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question3: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question4: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question5: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question6: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question7: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question8: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question9: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question10: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question11: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question12: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question13: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question14: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ],
          question15: [
            {required: true, validator: checkQuestion, trigger: 'blur'}
          ]
        },
        four: null,
        mobileVisible: true,
        feedBackVisible: false,
        questionnaireVisible: false,
        colors: ['#99A9BF', '#F7BA2A', '#FF9900'],


        consultingVisible: false,
        consultDataForm: {
          mobile: '',
          consultContent: ''
        },
        consultRules: {
          mobile: [
            {required: true, message: '手机号不能为空', trigger: 'blur'}
          ]
        },

      }
    },
    computed: {
      userId: {
        get() {
          return this.$store.state.user.id
        }
      }
    },
    methods: {
      clear() {
        this.$refs.dataForm.resetFields()
      },
      showFeedback() {
        if (this.userId === '') {
          this.feedBackVisible=false
          this.$confirm('您还没有登录，确定要跳转到登录页吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({name:'login'})
          })
        }else {
          this.feedBackVisible = true
          this.$nextTick(() => {
            this.$refs.dataForm.resetFields()
          })
        }
      },
      consultingback() {
        // if (this.userId === '') {
        //   this.consultingVisible=false
        //   this.$confirm('您还没有登录，确定要跳转到登录页吗', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {
        //   this.$router.push({name:'login'})
        //   })
        // }else {
        //   this.consultingVisible = true
        // }
        this.$router.push({name:'one-stop'})
      },
      consultCancel() {
        this.consultingVisible = false
      },


      handleClose(done) {
        done()
      },
      top() {
        this.four = setInterval(this.fourScrollBy, 10)
      },
      showQuestionnaire() {
        if (this.userId === '') {
          this.feedBackVisible=false
          this.$confirm('您还没有登录，确定要跳转到登录页吗', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push({name:'login'})
          })
        }else {
          this.questionnaireVisible = true
          this.$nextTick(() => {
            this.$refs.questionForm.resetFields()
          })
        }
      },
      consultSubmit() {
        this.$refs['consult'].validate((valid) => {
          if (valid) {
            this.$confirm('确定提交咨询？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$http({
                url: this.$http.adornUrl(this.$api.CONSULT.SAVE),
                method: 'post',
                data: this.$http.adornData(this.consultDataForm)
              }).then(({data}) => {
                if (data && data.code === 0) {
                  this.$message.success('提交成功')
                  this.$refs.consult.resetFields()
                  this.consultingVisible = false
                } else {
                  this.$message.error(data.msg)
                }
              })
            })
          }
        })
      },
      questionnaireSubmit() {
        this.$refs['questionForm'].validate((valid) => {
          if (valid) {
            this.$confirm('确定提交？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$http({
                url: this.$http.adornUrl(this.$api.CQC.SAVE),
                method: 'post',
                data: this.$http.adornData(this.questionForm)
              }).then(({data}) => {
                if (data && data.code === 0) {
                  this.$message.success('提交成功')
                  this.$refs.questionForm.resetFields()
                  this.questionnaireVisible = false
                } else {
                  this.$message.error(data.msg)
                }
              })
            })
          }
        })
      },
      dataFormSubmit() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$confirm('确定提交反馈?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$http({
                url: this.$http.adornUrl(this.$api.FEEDBACK.SAVE),
                method: 'post',
                data: this.$http.adornData(this.dataForm)
              }).then(({data}) => {
                if (data && data.code === 0) {
                  this.$message.success('操作成功')
                  this.$refs.dataForm.resetFields()
                  this.feedBackVisible = false
                } else {
                  this.$message.error(data.msg)
                }
              })
            }).catch(() => {
            })
          }
        })
      },
      fourScrollBy(eachHeight) {
        if (document.documentElement && document.documentElement.scrollTop) //IE
        {
          if (document.documentElement.scrollTop <= 0) {
            clearInterval(this.four)
          } else {
            window.scrollBy(0, -30)
          }
        } else { //Chrome不支持documentElement.scrollTop
          if (document.body.scrollTop <= 0) {
            clearInterval(this.four)
          } else {
            window.scrollBy(0, -30)
          }
        }
      }
    }
  }
</script>
<style scoped>
  .tools {
    width: 46px;
    position: fixed;
    right: 0px;
    bottom: 130px;
    z-index: 999;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.11);
  }

  .tools .item {
    width: 100%;
    height: 64px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    position: relative;
  }

  .tools .item:hover {
    background: #05a4ff;
    color: #fff;
  }

  .tools .item .titletip {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 17px;
    margin-top: 1px;
  }

  .feedback-dialog .rate >>> .el-form-item__content {
    vertical-align: middle;
    display: flex;
    align-items: center;
    height: 36px;
  }

  .feedback-dialog .rate >>> .el-rate {
    height: auto;
  }

  .feedback-dialog >>> .el-rate__icon {
    font-size: 26px;
  }

  .i-title {
    margin: 7px 0 12px;
    font-weight: bold;
    font-size: 17px;
    color: #349bde;
    padding-bottom: 12px;
    border-bottom: 1px dashed #E4E7ED;
  }
  .question-title {
    text-align: center;
    margin: 0 auto;
    font-weight: bold;
    font-size: 20px;
    /*color: #349bde;*/
    padding-bottom: 12px;
    /*border-bottom: 1px dashed #E4E7ED;*/
  }
  .question {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .radio{
    margin-left: 10px;
  }
</style>
